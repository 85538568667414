<template>
  <v-container>
    <!-- Header -->
    <v-col
      align="start"
      justify="center"
      class="pa-0 mb-10"
    >
      <v-icon
        color="primary"
        style="cursor: pointer;"
        @click="$router.go(-1)"
      >
        {{ icons.mdiChevronLeft }}
      </v-icon>
      <h3
        style="display: inline-block"
        class="ms-4"
      >
        Detail Employee
      </h3>
    </v-col>

    <!-- Header Info -->
    <header-info
      :titles="titles"
      :values="values"
      style="margin-left: 0; margin-right: 0; margin-bottom: 2rem;"
    />

    <!-- Table -->
    <v-data-table
      :headers="headers"
      :items="employeeDetails"
      item-key="name"
      class="elevation-1"
    >
      <template v-slot:item.no="{ item }">
        {{ employeeDetails.findIndex((employee) => employee.id === item.id) + 1 }}.
      </template>

      <template v-slot:item.paidDate="{ item }">
        {{ item.datePaid ? formatDate(item.datePaid) : '--' }}
      </template>

      <template v-slot:item.amount="{ item }">
        {{ formattedCurrency(item.amount) }}
      </template>

      <template v-slot:item.currentTotalLoan="{ item }">
        {{ formattedCurrency(item.currentTotalLoan) }}
      </template>

      <template v-slot:item.totalReceived="{ item }">
        {{ formattedCurrency(item.totalReceived) }}
      </template>

      <template v-slot:item.status="{ item }">
        <div :style="`color: ${employeeStatusColor[item.status]}`">
          {{ employeeStatusText[item.status] }}
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiChevronLeft } from '@mdi/js'

import HeaderInfo from './components/HeaderInfo.vue'

export default {
  name: 'EmployeeDetail',
  components: { HeaderInfo },
  setup() {
    const { isDark } = useAppConfig()
    const employeeStatusText = {
      waitingApproval: 'Waiting Approval',
      approved: 'Approved',
      sent: 'Sent',
      paid: 'Paid',
    }
    const employeeStatusColor = {
      waitingApproval: '#FCBE2D',
      approved: '#9E9E9E',
      sent: '#03A9F4',
      paid: '#44A780',
    }

    return {
      isDark,
      employeeStatusText,
      employeeStatusColor,
      icons: { mdiChevronLeft },
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'No.',
          align: 'start',
          value: 'no',
        },
        {
          text: 'Paid date',
          align: 'start',
          value: 'paidDate',
        },
        { text: 'Amount', align: 'end', value: 'amount' },
        { text: 'Loan', align: 'end', value: 'currentTotalLoan' },
        { text: 'Received', align: 'end', value: 'totalReceived' },
        { text: 'Status', align: 'end', value: 'status' },
      ],
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
    employeeGroupID() {
      return this.$route.params.id
    },
    currentEmployeeData() {
      return this.$store.getters['manageSalary/getCurrentEmployee']
    },
    currentProject() {
      return this.$store.getters['manageSalary/getCurrentProject']
    },
    titles() {
      return ['Name', this.currentEmployeeData.email, this.employeeGroupName, this.currentEmployeeData.accountNumber]
    },
    values() {
      return [
        this.currentEmployeeData.name,
        this.currentEmployeeData.phoneNumber,
        this.currentProject.name,
        this.currentEmployeeData.bankAccount,
      ]
    },
    employeeGroupName() {
      return this.$store.getters['manageSalary/getCurrentEmployeeGroup'].name
    },
    employeeDetails() {
      return this.$store.getters['manageSalary/getEmployeeDetails']
    },
  },
  async created() {
    await this.$store.dispatch('manageSalary/getEmployeeByID', {
      sub_id: this.user.sub_id,
      ID_employee: this.employeeGroupID,
    })
  },
  methods: {
    formattedCurrency(number) {
      return number ? new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(number) : 0
    },
    formatDate(date) {
      return this.$moment(new Date(date - new Date().getTimezoneOffset())).format('DD MMM YYYY HH:mm')
    },
  },
}
</script>

<style lang="scss" scoped>
.v-btn.action-btn {
  width: 36px;
  min-width: 36px;
}
</style>